<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04"><img src="@/assets/front_component/images/assets/back02.png" alt="雲"></div>
      </div>
      <div class="left_area">
        <div class="img01"><img src="@/assets/front_component/images/assets/back01.png" alt="雲"></div>
      </div>
    </div>
    <div class="en_ttl en"><span>CEO MESSAGE</span></div>
    <div class="content_wrap">
      <h2>社長メッセージ</h2>
      <div class="mascot_wrap">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君">
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li><router-link :to="{ name: 'Front top' }">ホーム</router-link></li>
            <li><span>社長メッセージ</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <article class="post_area">
              <h1>社長メッセージ</h1>
              <div class="two_column">
                <div class="left_area">
                  <div style="text-align: center;">
                    <img src="@/assets/front_component/images/ceo.jpg" alt="画像">
                  </div>
                </div>
                <div class="right_area">
                  <div v-html="ceo.content"></div>
                </div>
              </div>
            </article>
          </div>
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥"></div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/kusa02.png" alt="草"></div>
          <div class="deco_wrap03"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap06"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap07"><img src="@/assets/front_component/images/assets/kusa05.png" alt="草"></div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap09"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap10"><img src="@/assets/front_component/images/assets/kusa07.png" alt="草"></div>
          <div class="deco_wrap11"><img src="@/assets/front_component/images/assets/kusa06.png" alt="草"></div>
          <div class="deco_wrap12"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap13"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap14"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap15"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap16"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap17"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球"></div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球"></div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球"></div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー">
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
        <ul class="menu_list">
          <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
          <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' }}">サンクス&ホメロンカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' }}">創発カード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' }}">気づき日報</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'photolog' }}">フォトログ</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'column' }}">コラム</router-link></li>
        </ul>
    </div>
  </div>
</template>

<script>
import message from "@/apis/Message";
import dayjs from 'dayjs';
import MessageType from "@/const/MessageType.json";
import $ from 'jquery';

import "@/assets/front_component/js/functions.js";

export default {
  name: "message",
  data() {
    return {
      ceo: {},
      ceo_messages: [],
      ceo_id:"",
      loader: false,
    };
  },
  components: {},
  beforeCreate(){
    $(function(){   
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.messageCeo('ceo');
  },
  methods: {
    messageCeo(type) {
      this.loader = true;

      message.list(type)
      .then((response) => {
        if (response != null) {
          this.ceo_messages = response.data.data;
          console.log(this.ceo_messages);
          this.ceo_id = this.ceo_messages[0].id;
          this.getCeo();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loader = false;
      });
    },
    getCeo() {
      message.show(this.ceo_id)
      .then(
        (response) => {
          if (response != "") {
            this.ceo = response.data.data;
            console.log(this.ceo);
            console.log(this.ceo.content);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(() => {})
      .finally(() => {});
    },
  },
  mounted() {
    document.body.className = 'page_template';
    this.$nextTick(function() {
      $(function(){
        $('.menu_icon').click(function(){
          if($('.menu_list_wrap').hasClass('show')){
            $('.menu_list_wrap').removeClass('show');
          }else{
            $('.menu_list_wrap').addClass('show');
          }
        });
      });
      $(document).click(function(event) {
        if(!$(event.target).closest('.menu_wrap').length) {
          $('.menu_list_wrap').removeClass('show');
        }
      });
      $(function(){
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if(scroll > pagetop_show && scroll>60){
            $('.pagetop_wrap').addClass('show');
          } else {
            $('.pagetop_wrap').removeClass('show');
          }
        });
      });
      $(function(){
        $('#js-loader').delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      //Jquery
      // youtubeがタグの構成上表示出来ないので以下でタグを変換している
      $(function () {
        $("figure.media").each(function () {
          var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
          $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
          $(this)
            .children()
            .replaceWith(
              "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
            );
            console.log($y_url);
          $(this)
            .children(".post_youtube")
            .attr("src", "https://www.youtube.com/embed/" + $y_url);
        });
      });
    });
  },
  updated(){
    console.log('アップデート');
  }
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
